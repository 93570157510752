exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-webdev-js": () => import("./../../../src/pages/webdev.js" /* webpackChunkName: "component---src-pages-webdev-js" */),
  "component---src-pages-wt-privacy-policy-en-js": () => import("./../../../src/pages/WT-PrivacyPolicyEN.js" /* webpackChunkName: "component---src-pages-wt-privacy-policy-en-js" */),
  "component---src-pages-wt-privacy-policy-jp-js": () => import("./../../../src/pages/WT-PrivacyPolicyJP.js" /* webpackChunkName: "component---src-pages-wt-privacy-policy-jp-js" */)
}

